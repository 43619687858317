import firebase from "firebase/compat/app";
import  "firebase/compat/auth";
import  "firebase/compat/database";

const firebaseConfig = {
    apiKey: "AIzaSyA-syAS_sFcRY9xBTeIjSNuY2qxtLLJ6JQ",
    authDomain: "passsafe-6a5d8.firebaseapp.com",
    databaseURL: "https://passsafe-6a5d8.firebaseio.com",
    projectId: "passsafe-6a5d8",
    storageBucket: "passsafe-6a5d8.appspot.com",
    messagingSenderId: "765354628458",
    appId: "1:765354628458:web:a049d88399b8a2451a0113",
    measurementId: "G-P739DGYVJ4"
  };
  const app = firebase.initializeApp(firebaseConfig);

  export const auth = app.auth();
  export const database = app.database();