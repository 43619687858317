import React, { useRef, useState } from 'react';
import { Card, Form, Button, Container } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext'
import { Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { COLORS } from './colors';


export default function Signup() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { signup, currentUser } = useAuth()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match!')
        } try {
            setLoading(true)
            await signup(emailRef.current.value, passwordRef.current.value)
            navigate("/")
        }
        catch (e) {
            setError(e.toString())
        }
        setLoading(false)
    }
    return (<>
        <div className='text-center mb-4'>
            <span style={{ color: COLORS.green_light }}>Passwords</span>
            <span style={{ marginLeft: '10px', color: 'white', fontSize: '1.7em' }}>7</span>
        </div>
        <Container style={{ maxWidth: '500px' }}>
            <Card>
                <Card.Body>
                    <h2 className='text-center mb-4'>Sign Up</h2>
                    {currentUser && currentUser.email}
                    {error && <Alert variant='danger'>{error}</Alert>}

                    <Form onSubmit={handleSubmit}>
                        <Form.Group id='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type='email' ref={emailRef} required></Form.Control>
                        </Form.Group>
                        <Form.Group id='password'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type='password' ref={passwordRef} required></Form.Control>
                        </Form.Group>
                        <Form.Group id='passwordconfirm'>
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control type='password' ref={passwordConfirmRef} required></Form.Control>
                        </Form.Group>
                        <Button disabled={loading} className='w-100' type='submit'>Sign Up</Button>
                    </Form>
                </Card.Body>
            </Card >
        </Container>
        <div className='text-center mt-2' style={{ color: COLORS.blue_light, fontSize: '.8em' }}>
                Already have an account or want to use Social Media? <Link to="/login" style={{ color: COLORS.green_light }}>Login / Social Media</Link>
            </div>
      
    </>

    )
}