import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { auth } from '../firebase'
import { useAuth } from '../contexts/AuthContext'
import { database } from '../firebase'
import { ref, onValue, set } from 'firebase/database';
import AddPassword from './AddPassword';
import PasswordList from './PasswordList';
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import { Encryptor } from './Encryptor';
import moment from 'moment';
import { COLORS } from './colors';



export default function MainScreen() {
  const [show, setShow] = useState(false);
  const { currentUser } = useAuth()
  const [data, setData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    // Regsiter Web Access
    // TODO: sort alphabetically
    // TODO: have settings
    // TODO: have favourites area
    // TODO: Edit/View does not allow to set favroutie
    // TODO: Edit/View does not close on X

    // onValue(ref(database, 'access/page_counts'), (snapshot) => {
    //   let count = snapshot?.val() ?? 0;
    //   count = count + 1;
    //   set(ref(database, 'access/page_counts'),count)
    // })


    // if (currentUser == null) {
    //   navigate("/login")
    // }
    const path = 'users/' + currentUser.uid + '/passwords'
    const dbRef = ref(database, path);

    onValue(dbRef, (snapshot) => {
      let array = []
      snapshot.forEach(element => {
        const item = element.val()
        item.uuid = element.key
        array.push(item)
      })
      setData(array)
    })
  }, [currentUser])


  function logout() {
    console.log('logout')
    navigate("/login")
    auth.signOut()
  }


  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const onLoginFormSubmit = (e) => {
    console.log(`Title: ${e.title}`)
    console.log(`Username: ${e.user}`)
    console.log(`Password: ${e.password}`)
    console.log(`Website: ${e.website}`)
    console.log(`Comment: ${e.comment}`)
    console.log(`Favorite: ${e.favorite}`)
    const uuid = uuidv4()
    e.uuid = uuid
    const path = 'users/' + currentUser.uid + '/passwords/' + uuid;
    const dbRef = ref(database, path);
    const map = {
      'title': Encryptor(e.title),
      'username': Encryptor(e.user),
      'password': Encryptor(e.password),
      'website': Encryptor(e.website),
      'comment': Encryptor(e.comment),
      'isFavourite': e.favorite,
      'uuid': e.uuid,
      'created': moment().format('yyyy-MM-DD HH:mm:ss'),
      'updated': '',
    }
    set(dbRef, map)
    setShow(false)
  }

  return (<>

  {/* remove this into a logo element */}
      <div className='text-center mb-4'>
        <span style={{ color: COLORS.green_light }}>Passwords</span>
        <span style={{ marginLeft: '10px', color: 'white', fontSize: '1.7em' }}>7</span>
      </div>


      <div className="d-flex align-items-center justify-content-center mb-3" >
        <Button variant="success" onClick={handleShow}>
          Add/Edit a Password Entry
        </Button>
      </div>




      <PasswordList entries={data}></PasswordList>
      <Button className='w-100 mb-10' onClick={logout}>Logout</Button>
      <br></br>

      <Modal show={show} block="false">
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Add Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddPassword onSubmit={onLoginFormSubmit} />
        </Modal.Body>
      </Modal>
  </>
  )
}
