import React, { useRef, useState } from 'react';
import { Card, Form, Button, Container } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext'
import { Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'

const pw_bg_dark = '#0A4D68'
const pw_bg_light = '#088395'
const pw_light_blue = '#05BFDB'
const pw_light_green = '#00FFCA'

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login, currentUser, loginUpWithGoogle, loginUpWithApple } = useAuth()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    // TODO: Redirect to dashboard when logged in.

    async function googleLogin(e) {
        e.preventDefault()
        try {
            setLoading(true)
            await loginUpWithGoogle()
            navigate("/")
        }
        catch (e) {
            setError(e.toString())
        }
        setLoading(false)
    }

    async function appleLogin(e) {
        e.preventDefault()
        try {
            setLoading(true)
            await loginUpWithApple()
            navigate("/")
        }
        catch (e) {
            setError(e.toString())
        }
        setLoading(false)
    }

    async function emailLogin(e) {
        e.preventDefault()
        try {
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            navigate("/")
        }
        catch (e) {
            setError(e.toString())
        }
        setLoading(false)
    }
    return (<>
            <div className='text-center mb-4'>
                <span style={{ color: pw_light_green }}>Passwords</span>
                <span style={{ marginLeft: '10px', color: 'white', fontSize: '1.7em' }}>7</span>
            </div>
            <Container style={{ maxWidth: '500px' }}>
                <Card className='w-100'>
                    <Card.Body>
                        <h2 className='text-center mb-4'>Login</h2>
                        {currentUser && currentUser.email}
                        {error && <Alert variant='danger'>{error}</Alert>}

                        <Form onSubmit={emailLogin}>
                            <Form.Group id='email' className='mb-4'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type='email' autoComplete="off" ref={emailRef} required></Form.Control>
                            </Form.Group>
                            <Form.Group id='password'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type='password' autoComplete="off" ref={passwordRef} required></Form.Control>
                            </Form.Group>
                            <div className='text-center'>
                                <Button
                                    disabled={loading}
                                    className='w-50 mt-4'
                                    type='submit'
                                    style={{ backgroundColor: pw_bg_dark, borderColor: pw_light_green }}>Login</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card >
            </Container>

            <div className='text-center mt-2' style={{ color: pw_light_blue, fontSize: '.8em' }}>
                or login/sign up using
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }} >
                <img src="./google.png" alt="..." height="50px" onClick={googleLogin} className="rounded-circle shadow-lg d-flex align-items-center justify-content-center "></img>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px' }}>
                <img src="./apple.png" alt="..." onClick={appleLogin} height="40px" />
            </div>

            <div className='text-center mt-2' style={{ color: pw_light_blue, fontSize: '.8em' }}>
                Need an account? <Link to="/signup" style={{ color: pw_light_green }}>Sign up</Link>
            </div>
        </>
    )
}