import Signup from './Signup';
import MainScreen from './MainScreen';
import Login from './Login';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { AuthProvider } from '../contexts/AuthContext';
import { COLORS } from './colors';


// import './App.css';

// static const color1 = Color(0xFF0A4D68);
// static const color2 = Color(0xFF088395);
// static const color3 = Color(0xFF05BFDB);
// static const color4 = Color(0xFF00FFCA);

function App() {

  return (
    <div style={{backgroundColor:COLORS.bg_dark}}>
     <Container className='d-flex align-items-center justify-content-center' style={{minHeight:'100vh'}}>
      <div className='w-100'>
      <Router>
        <AuthProvider>
          <Routes>
            <Route exact path="/" Component={MainScreen} />
            <Route path="/signup" Component={Signup} />
            <Route path="/login" Component={Login} />
          </Routes>
        </AuthProvider>
      </Router>
      </div>
      </Container>
    </div>
  );
}

export default App;
