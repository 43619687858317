import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../firebase'
import firebase from "firebase/compat/app";
import { useNavigate } from 'react-router-dom'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}
export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    function signup(email, password) {
        console.log(`creating user with ${email} ${password}`)
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function login(email, password) {
        console.log(`creating user with ${email} ${password}`)
        return auth.signInWithEmailAndPassword(email, password)
    }

    function loginUpWithGoogle() {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        // const appleProvider = firebase.auth.OAuthProvider('apple.com');
        // appleProvider.addScope('email');
        // appleProvider.addScope('name');
        return auth.signInWithPopup(googleProvider)
      
    }

    function loginUpWithApple() {
        const provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        return auth.signInWithPopup(provider)
      
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
            console.log(`auth state changed.`)
            if (user === null) {
                navigate("/login")
            }
        })
        return unsubscribe
    }, [])

    const value = {
        currentUser,
        signup,
        login,
        loginUpWithGoogle,
        loginUpWithApple
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
