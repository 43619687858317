import { Button, FloatingLabel, Form } from 'react-bootstrap';
import React, { useState } from 'react'
import { StarFill } from 'react-bootstrap-icons';

export default function AddPassword({ onSubmit }) {

    const [title, setTitle] = useState('');
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [website, setWebsite] = useState('');
    const [comment, setComment] = useState('');
    const [favorite, setFavorite] = useState(false);
    function toggleFav() {
        setFavorite(!favorite)
    }
    function handleSubmit(e) {
        e.preventDefault()
        onSubmit({
            'title': title,
            'user': user,
            'password': password,
            'website': website,
            'comment': comment,
            'favorite': favorite,
        })
    }
    return (
        <Form onSubmit={handleSubmit} autoComplete='off'>
            <div className='mb-4' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StarFill onClick={toggleFav} color={favorite ? "orange" : "grey"} size={30} />
            </div>
            <Form.Group controlId="title" className='mb-3'>
                <FloatingLabel label='Title'>
                    <Form.Control
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}>
                    </Form.Control>
                </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="user" className='mb-3'>
                <FloatingLabel label='User'>
                    <Form.Control
                        value={user}
                        onChange={(e) => setUser(e.target.value)} />
                </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="password" className='mb-3'>
                <FloatingLabel label='Password'>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="Website" className='mb-3'>
                <FloatingLabel label='WebSite'>
                    <Form.Control
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </FloatingLabel>
            </Form.Group>
            {/* mb-3 margin botton 3 */}
            <Form.Group controlId="comment" className='mb-3'>
                
                <Form.Control
                    placeholder="Comment"
                    as="textarea" rows={3}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </Form.Group>
            <Button variant="primary" type="submit">Save
            </Button>
        </Form >
    )
}