import React, { useState, useRef, useEffect } from 'react'
import ViewEditPassword from './ViewEditPassword';
import { TrashFill } from 'react-bootstrap-icons';
import { Table, Form } from 'react-bootstrap'
import { Modal } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import { Decryptor } from './Encryptor';
import { Anonymizer } from './Anonymizer';
import { ref, set, remove } from 'firebase/database';
import { useAuth } from '../contexts/AuthContext'
import { database } from '../firebase'
import { confirm } from "./Confirmation";

export default function PasswordList({ entries }) {

    const [filteredEntries, setFilteredEntries] = useState(entries)
    useEffect(() => {
        setFilteredEntries(entries)
    }, [entries])
    const searchRef = useRef()

    function searchChange() {
        const search = searchRef.current.value
        const newEntries = []
        entries.forEach(element => {
            console.log(Decryptor(element.title))
            if (
                Decryptor(element.title).toLowerCase().includes(search.toLowerCase())|| 
                Decryptor(element.user).toLowerCase().includes(search.toLowerCase())|| 
                Decryptor(element.comment).toLowerCase().includes(search.toLowerCase())|| 
                Decryptor(element.website).toLowerCase().includes(search.toLowerCase())
                 ) {
                newEntries.push(element);
            }
        })

        setFilteredEntries(newEntries)
    }

    const { currentUser } = useAuth()
    const [show, setShow] = useState(false);
    const [currentEntry, setCurrentEntry] = useState();

    async function deleteEntry(e) {
        if (await confirm("Are your sure?")) {
            const path = 'users/' + currentUser.uid + '/passwords/' + e.uuid;
            const dbRef = ref(database, path);
            remove(dbRef, e)
        }
    }
    function onSubmit(e) {
        const path = 'users/' + currentUser.uid + '/passwords/' + e.uuid;
        const dbRef = ref(database, path);
        set(dbRef, e)
        console.log(e)
        setShow(false)
    }

    function editPassword(e) {
        setCurrentEntry(e)
        setShow(true)
    }
    function handleClose() {
        setShow(false)
    }
    return (
        <>
            <Form >
                <Form.Group id='search'>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Search for"
                        className="mb-3"
                    >
                        <Form.Control
                            type='text' ref={searchRef}
                            // placeholder="Search for"
                            onChange={searchChange}>
                        </Form.Control>
                    </FloatingLabel>
                </Form.Group>
            </Form>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>User</th>
                        <th>Password</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredEntries.map((e) => {
                        return (
                            <tr key={e.uuid}>
                                <td onClick={() => editPassword(e)}>{Decryptor(e.title)}</td>
                                <td onClick={() => editPassword(e)}>{Decryptor(e.user)}</td>
                                <td onClick={() => editPassword(e)}>{Anonymizer(Decryptor(e.password))}</td>
                                <td><TrashFill onClick={() => deleteEntry(e)} color={"red"} size={20} /></td>
                            </tr>
                        )
                    })}

                </tbody>
            </Table>
            <Modal show={show} block="false">
            <ViewEditPassword entry={currentEntry} onSubmit={onSubmit} handleClose={handleClose}></ViewEditPassword>
            </Modal>

        </>
    )
}
